import React from "react"

import { Badge, Stack } from "@kiwicom/orbit-components"
import {
  Bus,
  Radar,
  Search,
  SelfTransfer,
  UserGroup,
} from "@kiwicom/orbit-components/icons/"

import { RolesNames } from "types/person"

export const RoleBadge = ({ role }: { role: RolesNames }) => {
  let roleName: string
  let icon: React.ReactNode
  if (role == RolesNames.DRIVERS) {
    roleName = "Driver"
    icon = <Bus />
  } else if (role == RolesNames.HUB_SUPPORT) {
    roleName = "Hub Support"
    icon = <SelfTransfer />
  } else if (role == RolesNames.MAINTENANCE) {
    roleName = "Maintenance"
    icon = <UserGroup />
  } else if (role == RolesNames.OPERATIONS) {
    roleName = "Operations"
    icon = <UserGroup />
  } else if (role == RolesNames.ADMINS) {
    roleName = "Admin"
    icon = <Radar />
  } else if (role == RolesNames.PREVIEW_USERS) {
    roleName = "Preview User"
    icon = <Search />
  } else {
    roleName = role
  }
  return (
    <Badge icon={icon} type="successSubtle">
      {roleName}
    </Badge>
  )
}

export const RoleBadgeList = ({ roles }: { roles: RolesNames[] }) => (
  <Stack direction="row" wrap>
    {roles.length == 0 && <Badge type="neutral">None</Badge>}
    {roles.map((role) => (
      <RoleBadge role={role} key={role} />
    ))}
  </Stack>
)
