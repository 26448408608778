// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { navigate } from "gatsby"
import styled from "styled-components"

import {
  Alert,
  Heading,
  InputField,
  Loading,
  Stack,
  Text,
} from "@kiwicom/orbit-components"

import { EmberApiError } from "api/errors"
import { searchPeople } from "api/person"

import Container from "components/container"
import { ListTile } from "components/generic/multi-column-layout"
import { AdminLayout } from "components/layout-custom"
import { RoleBadge } from "components/people/role-badge"

import { Profile } from "types/person"

import { getPersonName } from "utils/name-utils"
import {
  updateQueryParam,
  updateStateFromUrl,
  useQueryString,
} from "utils/url-utils"

const SearchResultWrapper = styled.div`
  overflow-y: auto;
  border: 1px solid ${(props) => props.theme.orbit.borderColorCard};
  width: 100%;
  max-height: 650px;
`

const MAX_RESULTS = 100

const Page = () => {
  const [customers, setCustomers] = useState<Profile[]>([])
  const [requestedFilter, setRequestedFilter] = useState<string>("")
  const [loadedFilter, setLoadedFilter] = useState<string>(requestedFilter)
  const [fetching, setFetching] = useState<boolean>(false)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [error, setError] = useState<EmberApiError>(null)

  const { queryString } = useQueryString()
  const [loadedQueryString, setLoadedQueryString] = useState(false)

  const handleSetFilterString = (newFilterString) => {
    setRequestedFilter(newFilterString)
    updateQueryParam(queryString, { tag: "filter", value: newFilterString })
  }

  const urlParams = [
    {
      tag: "filter",
      setState: handleSetFilterString,
    },
  ]

  useEffect(() => {
    if (queryString && !loadedQueryString) {
      setLoadedQueryString(true)
      updateStateFromUrl(queryString, urlParams)
    }
  }, [queryString])

  useEffect(() => {
    // As soon as the user starts typing, we fire off a request to the backend. We don't use a fixed
    // while we're loading data, we don't cancel the in-flight request; instead we save the user
    // debounce delay, to ensure that things feel snappy to the user. If the user continues typing
    // input in `requestedFilter`. Then, then the first request complete, we'll see that the
    // requestedFilter is different from the loadedFilter we just finished fetching, and we'll
    // immediately re-issue a request. The `showSpinner` flag stays `true` the whole time, avoiding
    // an on-and-off flickering of the loading spinner.

    if (loadedFilter == requestedFilter) {
      // We're up to date, no more requests needed
      setShowSpinner(false)
      return
    }

    if (fetching) {
      // There's a request in flight -- we'll re-run this effect once the in-flight request has
      // finished
      return
    }

    setError(null)
    if (!requestedFilter) {
      // Don't query the backend for an empty string, just set the result set to empty
      setLoadedFilter(requestedFilter)
      setCustomers([])
      return
    }

    setFetching(true)
    setShowSpinner(true)
    searchPeople({
      request: { filter: requestedFilter, limit: MAX_RESULTS },
      onSuccess: (customers: Profile[]) => {
        setLoadedFilter(requestedFilter)
        setCustomers(customers)
        setFetching(false)
      },
      onError: (error: EmberApiError) => {
        setLoadedFilter(requestedFilter)
        setError(error)
        setFetching(false)
      },
    })
  }, [requestedFilter, loadedFilter, fetching])

  const CustomerTile = ({ customer }) => {
    return (
      <Stack>
        <ListTile
          dataTest="admin-customer-item"
          selectable
          onClick={() => {
            navigate(`/account/admin/customers/${customer.uid}/`)
          }}
        >
          <Stack spacing="XSmall" direction="column">
            <Heading type="title3">{customer.email}</Heading>
            <Stack direction="row">
              <Text>
                {getPersonName(customer)
                  ? getPersonName(customer)
                  : "Unknown Name"}
              </Text>
              {customer.roles.map((role) => (
                <RoleBadge role={role} key={role} />
              ))}
            </Stack>
          </Stack>
        </ListTile>
      </Stack>
    )
  }

  return (
    <AdminLayout title="Search for a Person" padded={true}>
      <Container>
        <Stack direction="column">
          <Text size="large" weight="bold">
            Search for a Person
          </Text>
          <InputField
            id="people-search-input"
            placeholder="Type an email, name, phone number, postcode or pass code..."
            value={requestedFilter}
            onChange={(event) => {
              const input = event.target as any
              const value = input.value
              handleSetFilterString(value)
            }}
          />
          {error ? (
            <Alert type="critical" title="Could not load customers">
              Check you are logged in with the right permissions and your
              internet connection is active. The error message was "
              {error.message}".
            </Alert>
          ) : (
            <Stack>
              {customers.length > 0 && (
                <SearchResultWrapper>
                  {customers.map((customer) => {
                    return (
                      <CustomerTile customer={customer} key={customer.uid} />
                    )
                  })}
                </SearchResultWrapper>
              )}
              {showSpinner ? (
                <Loading type="inlineLoader" text="Loading..." />
              ) : (
                requestedFilter && (
                  <Text size="small">
                    {customers.length}
                    {customers.length == MAX_RESULTS ? "+" : ""}
                    {` match${customers.length == 1 ? "" : "es"} found`}
                  </Text>
                )
              )}
            </Stack>
          )}
        </Stack>
      </Container>
    </AdminLayout>
  )
}

export default Page
